<template>
  <v-app>
    <loading :active.sync="is_call_api"></loading>
    <div style="background-color: rgb(243 246 249)">
      <h1 class="mt-5 mb-5" style="color: #3f4254">Quản lí gói dịch vụ</h1>
    </div>

    <div class="p-5">
<!--      <v-btn-->
<!--        rounded-->
<!--        color="primary"-->
<!--        class="mb-5"-->
<!--        x-small-->
<!--        large-->
<!--        @click="openDiaLogServicePackage"-->
<!--      >-->
<!--        <v-icon color="white" dark>mdi-plus</v-icon-->
<!--        ><span style="color: white">Thêm gói dịch vụ</span>-->
<!--      </v-btn>-->
      <div>
        <v-row>
          <v-col cols="3" class="d-flex align-items-center pb-0">
            <label class="text-body-1 font-weight-medium mb-6">Category:</label>
            <v-autocomplete
                class="pl-2"
                color="blue-grey lighten-2"
                outlined dense clearable
                :items="product_category_tags"
                item-text="name.vi"
                item-value="id"
                v-model="product_category_id_filter"
            ></v-autocomplete>
          </v-col>
        </v-row>
      </div>
      <div>
        <v-row>
          <v-col cols="12">
            <div class="table-responsive">
              <table
                class="table datatable-bordered table-head-custom table-vertical-center table-head-bg"
              >
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Tên dich vụ</th>
                    <th scope="col">Giá</th>
                    <th scope="col">Category</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(service_package, i) in service_packages">
                    <tr :key="i">
                      <td scope="row">{{ i + 1 }}</td>
                      <td class="">
                        <p
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                        >
                          {{ service_package.name }}
                        </p>
                      </td>
                      <td>
                        <p
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                          style="max-width: 300px"
                        >
                          {{ service_package.price | formatNumber }} VNĐ
                        </p>
                      </td>
                      <td>
                        <p
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                        >
                          {{service_package.product_category ? service_package.product_category.name.vi : ''}}
                        </p>
                      </td>
                      <td>
                        <div style="display: flex">
<!--                          <v-tooltip left>-->
<!--                            <template v-slot:activator="{ on, attrs }">-->
<!--                              <button-->
<!--                                class="btn btn-icon btn-light-warning btn-sm"-->
<!--                                v-bind="attrs"-->
<!--                                v-on="on"-->
<!--                                @click="editServicePackage(i)"-->
<!--                              >-->
<!--                                <span-->
<!--                                  class="svg-icon svg-icon-md svg-icon-primary"-->
<!--                                >-->
<!--                                  <v-icon color=""-->
<!--                                    >mdi-square-edit-outline</v-icon-->
<!--                                  >-->
<!--                                </span>-->
<!--                              </button>-->
<!--                            </template>-->
<!--                            <span>Edit</span>-->
<!--                          </v-tooltip>-->
<!--                          <v-tooltip right>-->
<!--                            <template v-slot:activator="{ on, attrs }">-->
<!--                              <button-->
<!--                                class="btn btn-icon btn-light-danger btn-sm ml-2"-->
<!--                                v-bind="attrs"-->
<!--                                v-on="on"-->
<!--                                @click="-->
<!--                                  deleteServicePackage(service_package.id)-->
<!--                                "-->
<!--                              >-->
<!--                                <span-->
<!--                                  class="svg-icon svg-icon-md svg-icon-primary"-->
<!--                                >-->
<!--                                  <v-icon color="">mdi-delete</v-icon>-->
<!--                                </span>-->
<!--                              </button>-->
<!--                            </template>-->
<!--                            <span>Delete</span>-->
<!--                          </v-tooltip>-->
                        </div>
                      </td>
                    </tr>
                  </template>
                  <template v-if="!service_packages.length">
                    <tr>
                      <td colspan="5" style="text-align: center">No Data</td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>

    <FormModal
      :show_dialog="dialogServicePackage"
      :type_form="type_form"
      :service_package="service_package"
      :benefits="benefits"
      :programme_tags="programme_tags"
      :product_categories="product_category_tags"
      @setDialog="(e) => (this.dialogServicePackage = e)"
      @resetData="getAllServicePackage"
    >
    </FormModal>
  </v-app>
</template>

<script>
import "vue-loading-overlay/dist/vue-loading.css";
import ApiService from "@/service/api.service";
import FormModal from "./FormModal";
import Swal from "sweetalert2";
export default {
  name: "ServicePackageList",
  components: {
    FormModal,
    Loading: () => import("vue-loading-overlay"),
  },
  data() {
    return {
      is_call_api: false,
      service_packages: [],
      service_package: {},
      dialogServicePackage: false,
      type_form: null,
      benefits: [],
      programme_tags: [],
      product_category_tags: [],
      product_category_id_filter: null,
    };
  },
  watch: {
    product_category_id_filter() {
      this.getAllServicePackage();
    }
  },
  created() {
    this.getAllServicePackage();
    this.getAllBenefit();
    this.getProgrammeLimit();
    this.getAllProductCategory();
  },
  methods: {
    getProgrammeLimit() {
      let vm = this;
      ApiService.get("prep-app/programme/limit")
        .then(function (res) {
          if (res.status === 200) {
            vm.programme_tags = res.data;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    async getAllBenefit() {
      let vm = this;
      await ApiService.get("prep-app/benefit").then(function (response) {
        if (response.status === 200) {
          vm.benefits = response.data;
        }
      });
    },
    async getAllServicePackage() {
      let vm = this;
      let url = 'prep-app/service-package?';
      if (this.product_category_id_filter) url += '&product_category='+this.product_category_id_filter;
      try {
        vm.is_call_api = true;
        let res = await ApiService.get(url);
        if (res.status === 200) {
          vm.service_packages = res.data;
          vm.is_call_api = false;
        }
      } catch (error) {
        vm.is_call_api = false;
        console.log(error);
      }
    },
    async getAllProductCategory() {
      let vm = this;
      await ApiService.get("prep-app/product-category/limit")
      .then(function (response) {
        if (response.status === 200) {
          vm.product_category_tags = response.data;
        }
      });
    },
    openDiaLogServicePackage() {
      this.type_form = "add";
      this.dialogServicePackage = true;
    },
    editServicePackage(index) {
      this.type_form = "update";
      this.service_package = this.service_packages[index];
      this.dialogServicePackage = true;
    },
    deleteServicePackage(service_package_id) {
      let vm = this;
      Swal.fire({
        title: "Are you sure you want to delete?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          ApiService.delete("prep-app/service-package/" + service_package_id)
            .then(function (res) {
              if (res.status === 200) {
                vm.$toasted.success("Delete successfully!!", {
                  theme: "toasted-primary",
                  position: "top-right",
                  duration: 4000,
                });
                vm.getAllServicePackage();
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      });
    },
  },
};
</script>

<style scoped>
.table.table-head-bg thead th {
  background-color: #2196f3eb !important;
  color: white !important;
}
</style>
