<template>
  <div>
    <loading :active.sync="is_call_api"></loading>
    <v-row justify="center">
      <v-dialog
        v-model="dialogServicePackage"
        max-width="1400px"
        scrollable
        persistent
      >
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span class="headline" v-if="type_form === 'add'"
                >Thêm gói dịch vụ</span
              >
              <span class="headline" v-else>Sửa gói dịch vụ</span>
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogServicePackage = false" right
                >mdi-close</v-icon
              >
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-row>
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Tên gói dịch vụ:</label></v-col
                    >
                    <v-col cols="8" class="p-0 m-0 ml-4"
                      ><v-text-field
                        dense
                        v-model="name_input"
                        filled
                      ></v-text-field
                    ></v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Display name:</label></v-col
                    >
                    <v-col cols="8" class="p-0 m-0 ml-4"
                      ><v-text-field
                        dense
                        v-model="display_name_input"
                        filled
                      ></v-text-field
                    ></v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Giá tiền:(VNĐ)</label></v-col
                    >
                    <v-col cols="8" class="p-0 m-0 ml-4">
                      <InputPrice
                        v-model="price_display_input"
                        @setPriceUnFormat="(e) => (this.price_input = e)"
                      ></InputPrice>
                      <!--                      <v-text-field dense v-model="price_input" filled></v-text-field>-->
                    </v-col>
                  </v-row>
                  <v-row class="">
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Các lợi ích:</label></v-col
                    >
                    <v-col cols="8" class="ml-4 p-0">
                      <v-autocomplete
                        solo
                        dense
                        multiple
                        chips
                        :items="benefits"
                        item-text="name"
                        item-value="id"
                        v-model="benefit_ids"
                      >
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row class="">
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Programmes:</label></v-col
                    >
                    <v-col cols="8" class="ml-4 p-0">
                      <v-autocomplete
                        solo
                        dense
                        multiple
                        chips
                        :items="programme_tags"
                        item-text="name.vi"
                        item-value="id"
                        v-model="programme_ids"
                      >
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row class="">
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Product Category:</label></v-col
                    >
                    <v-col cols="8" class="ml-4 p-0">
                      <v-autocomplete
                        solo
                        dense
                        :items="product_categories"
                        item-text="name.vi"
                        item-value="id"
                        v-model="product_category_id"
                      >
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Mô tả:</label></v-col
                    >
                    <v-col cols="8" class="p-0 m-0 ml-4">
                      <Editor v-model="description_input"></Editor>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <button
              class="btn btn-light-success text-uppercase mr-2"
              v-if="type_form === 'add'"
              @click="btnAddOrEditPackage(1)"
            >
              Add
            </button>
            <button
              class="btn btn-light-success text-uppercase mr-2"
              v-if="type_form === 'update'"
              @click="btnAddOrEditPackage(2)"
            >
              Update
            </button>
            <button
              class="btn text-uppercase mr-2"
              style="color: #f64e60"
              @click="dialogServicePackage = false"
            >
              Close
            </button>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import "vue-loading-overlay/dist/vue-loading.css";
import ApiService from "@/service/api.service";
export default {
  name: "FormModal",
  components: {
    InputPrice: () => import("../../../components/InputPrice"),
    Editor: () => import("@/components/ckeditor5/Editor.vue"),
    Loading: () => import("vue-loading-overlay"),
  },
  props: {
    show_dialog: {
      type: Boolean,
      default: false,
    },
    type_form: {
      type: String,
      default: "",
    },
    benefits: {
      type: Array,
      default: () => [],
    },
    programme_tags: {
      type: Array,
      default: () => [],
    },
    service_package: {},
    product_categories: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      is_call_api: false,
      service_package_id: null,
      name_input: null,
      display_name_input: null,
      price_display_input: "0",
      price_input: 0,
      description_input: "",
      benefit_ids: [],
      programme_ids: [],
      product_category_id: null,
    };
  },
  computed: {
    dialogServicePackage: {
      get() {
        return this.show_dialog;
      },
      set(val) {
        this.$emit("setDialog", val);
      },
    },
  },
  watch: {
    dialogServicePackage(val) {
      if (val) {
        this.setDataForm();
      }
    },
  },
  methods: {
    setDataForm() {
      if (this.type_form === "add") {
        this.name_input = null;
        this.display_name_input = null;
        this.price_display_input = "0";
        this.price_input = null;
        this.description_input = "";
        this.benefit_ids = [];
        this.programme_ids = [];
        this.product_category_id = null;
      } else {
        this.service_package_id = this.service_package.id;
        this.name_input = this.service_package.name;
        this.display_name_input = this.service_package.display_name;
        this.price_display_input = this.service_package.price.toString();
        this.price_input = this.service_package.price;
        this.description_input = this.service_package.description
          ? this.service_package.description
          : "";
        this.benefit_ids = this.service_package.benefits.map((e) => e.id);
        this.programme_ids = this.service_package.programmes.map((e) => e.id);
        this.product_category_id = this.service_package.product_category ? this.service_package.product_category.id : null;
      }
    },
    validateData() {
      let flat = true;
      if (this.name_input === "" || this.name_input == null) {
        this.$toasted.error("Chưa nhập tên !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        flat = false;
      }
      if (this.price_input == null || this.price_input === "") {
        this.$toasted.error("Chưa nhập giá!!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        flat = false;
      }
      if (this.product_category_id == null || this.product_category_id === "") {
        this.$toasted.error("Chưa chọn kỳ thi !!", {theme: "toasted-primary", position: "top-right", duration: 4000,});
        flat = false;
      }
      return flat;
    },
    async btnAddOrEditPackage(type) {
      let validateQues = this.validateData();
      if (!validateQues) {
        return;
      }
      let vm = this;
      let data = {
        name: this.name_input,
        price: this.price_input,
        benefit_ids: this.benefit_ids,
        programme_ids: this.programme_ids,
        product_category_id: this.product_category_id,
      };
      if (this.display_name_input) data.display_name = this.display_name_input;
      if (this.description_input) data.description = this.description_input;
      vm.is_call_api = true;
      if (type === 1) {
        ApiService.post("prep-app/service-package", data)
          .then(function (res) {
            if (res.status === 200) {
              vm.$toasted.success("Thêm thành công !!", {
                theme: "toasted-primary",
                position: "top-right",
                duration: 4000,
              });
              vm.$emit("resetData");
              vm.is_call_api = false;
              vm.dialogServicePackage = false;
            }
          })
          .catch(function (error) {
            vm.is_call_api = false;
            if (error.response.status === 422) {
              let data_response_error =
                error.response.data.error.message.split(".<br />");
              vm.showError(data_response_error);
            }
          });
      } else {
        ApiService.put(
          "prep-app/service-package/" + vm.service_package_id,
          data
        )
          .then(function (res) {
            if (res.status === 200) {
              vm.$toasted.success("Sửa thành công !!", {
                theme: "toasted-primary",
                position: "top-right",
                duration: 4000,
              });
              vm.$emit("resetData");
              vm.is_call_api = false;
              vm.dialogServicePackage = false;
            }
          })
          .catch(function (error) {
            vm.is_call_api = false;
            if (error.response.status === 422) {
              let data_response_error =
                error.response.data.error.message.split(".<br />");
              vm.showError(data_response_error);
            }
          });
      }
    },
    showError(data_error) {
      for (let i = 0; i < data_error.length; i++) {
        this.$toasted.error(data_error[i] + " !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
      }
    },
  },
};
</script>

<style scoped></style>
